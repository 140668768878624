@import "../styles/variables.css";

.root {
  /* display: flex; */
}
.bar {
  font-size: var(--font-size-small);
  display: flex;
  align-items: flex-end;
  background: rgb(240, 240, 240);
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: var(--margin);
  & .current,
  & .done {
    background-color: var(--maletek-teal);
  }
  &.error {
    & .label {
      background-color: var(--maletek-red);
      color: white;
    }
    & .current,
    & .done {
      background-color: var(--maletek-red);
      color: white;
    }
  }
}

.label {
  font-size: var(--font-size-normal);
  font-weight: bold;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin: 0;
  padding: 0 var(--margin);
  background-color: var(--maletek-teal);
}
.state {
  flex-grow: 1;
  text-align: center;
  display: inline-block;
  padding: 0 var(--margin-quarter);
  height: 2rem;
  line-height: 2rem;
  background-color: color-mod(var(--maletek-teal) lightness(95%));
  color: rgb(70, 70, 70);
  &:last-child {
    padding-right: 1rem;
  }
  &.left {
    text-align: left;
  }
}

.current {
  color: var(--color-text);
  font-size: var(--font-size-medium);
  line-height: 1.4;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: -1rem;
    transform-origin: center;
    transform: rotate(45deg);
    background-color: inherit;
    width: 2rem;
    height: 2rem;
  }
}

.errorMessage {
  color: red;
}
