@import "../../styles/variables.css";

.root {
  position: absolute;
  right: 10px;
  top: 100px;
  font-size: var(--font-size-small);
  z-index: 10;
  @media print {
    right: 4%;
  }
}

.legend {
  list-style: none;
}

.legendItem {
  display: flex;
  cursor: pointer;
  align-items: center;
  opacity: 0.5;
  padding: 5px;
  border-radius: 20px;
  transition: opacity 0.2s, background-color 0.2s;
  background-color: white;
}

.legendItem:hover {
  opacity: 1;
  background-color: var(--color-background-lighter);
}

.active {
  opacity: 1;
}

.unactive {
  background-color: var(--color-text-lighter) !important;
}

.symbol {
  margin-top: 10px;
  height: 10px;
  width: 10px;
  margin: 0px 5px;
  border-radius: 50%;
}

.series {
  margin-top: -3px;
}
