@import "../styles/variables.css";

.root {
  display: flex;
  width: 100%;
  align-items: flex-start;
  background: white;
  @media (--medium) {
    background: transparent;
    position: relative;
    padding: 0 0 0 var(--margin-double);
  }
  @media print {
    display: none;
  }
}

.toggle {
  position: fixed;
  top: 0;
  right: 0;
  padding: var(--margin-half);
  @media (--medium) {
    display: none;
  }
}

.navTitle {
  font-size: var(--font-size-medium);
}
.icon {
  margin-bottom: 0 !important;
}

@media (--small) {
  .menuList.menuOpen {
    display: flex;
    z-index: 900;
    width: 100vw;
    position: fixed;
    left: 0;
    background: white;
    padding-bottom: var(--margin);
    box-shadow: 0 4px 10px var(--maletek-teal);
    & .menuList {
      display: flex;
    }
  }
  .navTitle {
    font-size: inherit;
  }
  .icon {
    display: none;
  }
}

.menuList {
  display: none;
  width: 100%;
  list-style: none;
  flex-direction: column;

  padding: 0;
  @media (--medium) {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
  }
}
.spacer {
  @media (--medium) {
    flex: 1 1 200px;
  }
}
.menuItem {
  position: relative;
  white-space: nowrap;
  font-size: var(--font-size-large);
  margin: 0;
  padding: 0 var(--margin);

  & .menuItem {
    padding-left: 0 !important;
  }
  @media (--medium) {
    padding-left: 0;
    padding-right: 0;
    &:not(:last-child) {
      padding-right: var(--margin);
    }
  }

  & a {
    display: block;
    text-decoration: none;
    color: inherit;
    @media (--medium) {
      padding: 0 0.5rem;
    }
    &:hover {
      color: var(--maletek-red);
    }
  }

  &.active {
    background: var(--color-background-light);
    @media (--medium) {
      background: transparent;
    }

    > a {
      font-weight: 900;
      border-bottom: 2px solid black;
    }
  }
}

.menuItem .menuList {
  flex-direction: column;
  width: auto;
  min-width: 100%;
  transform: translateY(7px);
  transition: max-height 0.25s ease, transform 0.15s ease;
  padding-bottom: 0.25rem;

  @media (--medium) {
    position: absolute;
    background-color: var(--color-background-light);
    z-index: 100;
    max-height: 0;
    box-shadow: 0 4px 10px var(--maletek-teal);
    overflow: hidden;
    opacity: 0;
  }

  & .menuItem {
    padding-left: var(--margin-half);
    @media (--medium) {
      margin-left: 0;
      margin-right: 0.65rem;
    }
  }
}
.menuItem.rightAlign .menuList {
  right: 0;
}
.menuItem:hover .menuList {
  opacity: 1;
  transform: translateY(2px);
  max-height: 500px;
}

.line {
  border-top: 1px solid var(--maletek-black);
}

.gearIcon {
  margin-left: var(--margin);
}
