.root {
}

.list {
  list-style: none;
  padding: 0;
}
.unit {
  position: relative;
}

.row {
  display: flex;
}

.unit.temp {
  opacity: 0.8;
}

.delete {
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 1.5rem 0 0 1rem;
  font-size: 1.5rem;
  height: 2.2rem;
  line-height: 2.2rem;
  --webkit-appearance: none;
}

.tooltip {
  opacity: 0;
  transition: opacity 0.15s ease;
  pointer-events: none;
  position: absolute;
  right: 2.4rem;
  top: 1.85rem;
  padding: 0 0.5rem 0.15rem 0.75rem;
  border-radius: 4px;
  color: var(--color-text-light);
  font-size: var(--font-size-small);
  background-color: rgba(200, 200, 200, 0.2);
}
.delete:hover + .tooltip {
  opacity: 1;
}

.new {
  display: flex;
  gap: var(--margin-quarter);
}
.create {
  min-width: auto;
}
