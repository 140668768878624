.center {
  width: 500px;
  margin: 0 auto;
  max-width: 90vw;
}
.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.row {
  padding-bottom: 12px;
}

.right {
  margin-left: auto;
  margin-right: 0;
  float: right;
}

.barcode {
  width: "550px";
  height: "550px";
  background-repeat: "no-repeat";
}
