@import "../../styles/variables.css";

.countWrapper {
  position: relative;
  font-size: var(--font-size-large);
  font-weight: bold;
  min-width: 80px;
  max-width: 140px;
  @media print {
    page-break-inside: avoid;
  }
}

.countName {
  display: block;
  font-weight: 400;
  font-size: var(--font-size-normal);
}

.percentage {
  font-size: var(--font-size-normal);
  color: var(--color-text-lighter);
  margin-left: 10px;
}

.miniBarChart {
  margin-top: 0px;
  display: block;
  height: 8px;
  background-color: var(--color-text-lighter);
  width: 100%;
}

.miniBar {
  background-color: var(--color-green);
  position: absolute;
  height: 8px;
  z-index: 10;
}
