@import "../../styles/variables.css";

.root {
}
.minimal {
  font-size: inherit;
  text-decoration: inherit;
  color: inherit;
  &:hover {
    color: var(--color-text-lighter);
  }
}

.root.inline {
  display: inline-block;
}

.root.withTooltip {
  position: relative;
}
.root .tooltip {
  display: none;
}

.root:hover .tooltip {
  display: block;
  position: absolute;
  z-index: 100;
  background: var(--color-background-lighter);
  border: 1px solid var(--color-border-light);
  padding: 3px 5px;
  line-height: 1;
  top: 0%;
  right: 120%;
  white-space: nowrap;
}
