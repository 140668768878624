.root {
  z-index: 300;
  position: absolute;
  width: 70vw;
  left: 15vw;
  background-color: white;
  border: 1px solid var(--color-border-light);
  box-shadow: 0 0 50px rgb(140, 140, 140);
  padding: var(--margin);
  outline: none;
}

.close {
  cursor: pointer;
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;
  &:hover {
    color: gray;
  }
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(120, 120, 120, 0.6);
}
