.root {
}

.row {
  border-top: 1px solid #ccc;
  padding-bottom: 10px;
}

.label {
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
}
.leftGroup {
  display: flex;
  align-items: center;
}
.labelRequests {
  font-weight: normal;
  font-size: 13px;
  background: red;
  padding: 0 10px;
  color: white;
  border-radius: 10px;
}
.labelDate {
  font-weight: normal;
  font-size: 13px;
}
.toggle {
  float: right;
}
.hidden {
  visibility: hidden;
}
.details {
  display: none;
  &.open {
    display: block;
  }
  .change {
    display: flex;
    align-items: center;
  }
  & code {
    font-size: 13px;
  }
  & .data {
    white-space: pre-wrap;
    font-size: 13px;
    line-height: 1.4;
  }
  & span {
    display: inline-block;
  }
}
