@import "../styles/variables.css";

.root {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--margin-half) var(--margin);
  @media (--medium) {
    flex-direction: row;
    align-items: center;
  }
  @media print {
    position: absolute;
    right: 1%;
    top: 0%;
  }
}
.logo {
  margin: 0 0 0.5rem 0;
  font-weight: 100;
  font-size: 2rem;

  @media (--medium) {
    flex: 0 0 170px;
    font-size: 3rem;
  }
  & img {
    max-width: 100%;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
}
.nav {
}
.title {
  cursor: pointer;
}

.login {
  position: absolute;
}
