@import "../../styles/variables.css";

.root {
  display: inline-block;
  position: relative;
}

.graphTitle {
  margin-left: 0.4rem;
  margin-bottom: 0;
  height: 0px;
  z-index: 10;
}
