.root {
  background: var(--color-background-light);
  display: flex;
  padding: 1rem 1rem 1rem 0;
  margin: 0 0 1rem 0;
}

.icon {
  flex: 0 0 70px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content,
.content p,
.content ul,
.content li {
  font-size: var(--font-size-normal);
  margin: 0;
}
