@import "../../styles/variables.css";

.root {
  position: relative;
  display: flex;
  min-height: 80px;
  width: 100%;
  flex-wrap: wrap;
  @media print {
    max-width: 80%;
  }
}

.stackedCountWrapper {
  position: relative;
  font-weight: bold;
  width: 100%;
  max-width: 800px;
}

.nameContainer {
  opacity: 0;
  position: absolute;
  top: 40px;
  min-width: 300px;
  max-width: 300px;
  color: var(--color-text-light);
  display: flex;
  align-items: baseline;
  transition: opacity 0.1s ease-in;
}

.hidden {
  visibility: hidden;
}

.countName {
  display: block;
  width: auto;
  font-size: var(--font-size-normal);
  white-space: nowrap;
}

.quantity {
  width: 300px;
  display: block;
  margin-left: 5px;
  font-size: var(--font-size-small);
}

.barQuantity {
  width: 300px;
  display: inline;
  margin-left: 6px;
  opacity: 0.9;
  font-size: var(--font-size-small);
}

.percentage {
  margin: auto 10px;
  font-size: var(--font-size-normal);
  color: white;
  transition: opacity 0.1s ease-in;
}

.stackedBarChart {
  display: flex;
  margin-top: 8px;
  height: auto;
  background-color: var(--color-text-lighter);
  width: 100%;
}

.stackedBar {
  opacity: 0.9;
  display: flex;
  position: relative;
  background-color: var(--color-blue);
  height: 36px;
  z-index: 10;
  transition: opacity 0.1s ease-in;
}

.stackedBar:hover {
  opacity: 1;
}

.maxWidth {
  width: 320px;
}

.legend {
  margin-left: 30px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  max-height: 60px;
  flex-wrap: wrap;
  @media print {
    margin-top: 20px;
    margin-left: 0px;
    flex-wrap: nowrap;
  }
}

.item {
  color: var(--color-text);
  opacity: 0.8;
  display: inline-flex;
  align-items: baseline;
  width: auto;
  z-index: 10;
  padding: 5px;
  border-radius: 20px;
  background-color: white;
  transition: opacity 0.1s ease-in;
}

.item:hover {
  opacity: 1;
  background-color: var(--color-background-lighter);
}

.icon {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  z-index: -1;
  @media print {
    margin-left: 0px;
  }
}

.label {
  font-size: var(--font-size-small);
  margin: 0px 5px;
  white-space: nowrap;
  margin-top: -2px;
}

.hover {
  opacity: 1;
}

.hover.percentage {
  opacity: 0;
}

.hover.item {
  background-color: var(--color-background-lighter);
}
