:root {
  --maletek-black: #373233;
  --maletek-red: #e1251b;
  --maletek-light-red: #ea5754;
  --maletek-sand: #fbeed6;
  --maletek-teal: #c6d8d4;
  --maletek-green: #5f8350;
  --color-text: var(--maletek-black);
  --color-text-light: color-mod(var(--maletek-black) lightness(50%));
  --color-text-lighter: color-mod(var(--maletek-black) lightness(70%));
  --color-border-dark: rgb(158, 158, 158);
  --color-border: rgb(171, 171, 171);
  --color-border-light: rgb(226, 226, 226);
  --color-background-dark: rgb(185, 185, 185);
  --color-background: rgb(212, 212, 212);
  --color-background-light: rgb(238, 238, 238);
  --color-background-lighter: rgb(245, 245, 245);
  --color-green: #00ac17;
  --color-blue: #165eaf;
  --color-yellow: #e29a0c;
  --color-yellow-2: #f7c308;
  --color-orange: #f99e1e;
  --color-red: #c31414;
  --margin: 2rem;
  --margin-half: calc(var(--margin) / 2);
  --margin-double: calc(var(--margin) * 2);
  --margin-quarter: calc(var(--margin) / 4);
  --margin-eighth: calc(var(--margin) / 8);
  --font-size-base: 16px;
  --font-size-small: 0.85rem;
  --font-size-normal: 1rem;
  --font-size-medium: 1.3rem;
  --font-size-large: 1.7rem;
  --font-size-xlarge: 2.4rem;
  --font-size-xxlarge: 3.6rem;
}

@custom-media --small (max-width: 599px);
@custom-media --medium (min-width: 600px);
@custom-media --large (min-width: 1000px);
@custom-media --xlarge (min-width: 1600px);
