@import "../../styles/variables.css";

.root {
  max-width: 100%;
  overflow: auto;
}
.table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: var(--margin-half);
}

.head {
  text-align: left;
  .root th {
    border-bottom: 1px solid var(--color-border);
  }
}
.row:hover,
.row.hover {
  background: var(--color-background-light);
}
.row.clickable {
  cursor: pointer;
}
.cell {
  text-align: left;
  vertical-align: top;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid var(--color-border-light);
  &:last-child {
    padding-right: 0;
  }
  & h3,
  & h4,
  & h5,
  & p {
    margin: 0 0 4px 0;
  }
}
.cell a {
  display: block;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: var(--maletek-red);
  }
}
.cell.inline a {
  display: inline-block;
  margin-left: 10px;
}

.root.left .cell,
.body.left .cell,
.cell.left {
  text-align: left;
}

.root.center .cell,
.body.center .cell,
.cell.center {
  text-align: center;
}

.root.right .cell,
.body.right .cell,
.cell.right {
  text-align: right;
}

.root.top .cell,
.body.top .cell,
.cell.top {
  vertical-align: top;
}

.root.middle .cell,
.body.middle .cell,
.cell.middle {
  vertical-align: middle;
}
.root.bottom .cell,
.body.bottom .cell,
.cell.bottom {
  vertical-align: bottom;
}

.cell {
  & .hover {
    display: none;
  }
  &:hover {
    & .hover {
      display: block;
    }
    & .content {
      display: none;
    }
  }
}
