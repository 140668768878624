@import "../styles/variables.css";

.root {
}
.filters {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.select {
  width: auto;
  min-width: 160px;
  margin-left: 1rem;

  @media (--small) {
    min-width: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex: 1 1 40%;
  }
}
