@import "../../styles/variables.css";

.row {
  display: flex;
  & .root:not(:first-child) {
    margin-left: var(--margin-half);
  }
}

.root {
  position: relative;
  width: 100%;
  margin-bottom: var(--margin-half);
  &.inline {
    width: auto;
    &:not(:last-child) {
      margin-right: var(--margin-half);
    }
  }
  &.hidden {
    position: absolute;
    left: -100vw;
    margin: 0;
  }
}

.inputWrapper {
  position: relative;
}
.sufix {
  position: absolute;
  top: 0;
  height: 2.2rem;
  line-height: 2.2rem;
  color: var(--color-text-light);
  right: var(--margin-half);
}
.withSufix {
  /* Chrome, Safari, Edge, Opera */

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type="number"] {
    -moz-appearance: textfield;
  }
}

.label {
  font-weight: 400;
  color: var(--color-text);
  font-size: var(--font-size-small);
}
.input {
  width: 100%;
  padding: 0 1rem;
  height: 2.2rem;
  line-height: 1.75rem;
  border: 1px solid var(--color-border);
  border-radius: 1px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  outline: none;
  &:focus {
    border-color: var(--maletek-teal);
    box-shadow: 0 0 0 1px var(--maletek-teal);
  }
}

.input::placeholder,
.textArea::placeholder {
  color: var(--color-text-lighter);
}

.textArea {
  width: 100%;
  padding: 0 1rem;
  resize: vertical;
  min-height: 2.2rem;
  line-height: 1.75rem;
  border: 1px solid var(--color-border);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  outline: none;
  &.json {
    font-family: monospace;
    font-size: 0.9em;
  }
}
.withError .input {
  border-color: var(--color-red) !important ;
  background: color-mod(var(--color-red) lightness(95%));
}

.clickToEdit {
  & .input {
    position: relative;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    background-color: white;
    color: var(--color-text);
    &:focus {
      z-index: 11;
      border-color: var(--color-border);
    }
  }
  & .edit {
    font-size: var(--font-size-small);
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
    color: var(--maletek-green);
    z-index: 10;
    cursor: pointer;
  }
  &.readOnly .edit {
    display: none;
  }
}

.root .input[readonly],
.root.readOnly .input[readonly],
.root.readOnly .textArea[readonly] {
  background: var(--color-background-lighter);
  border-color: var(--color-border-light);
  color: var(--color-text-light);
}

.error {
  margin-top: 0;
  color: var(--color-red);
  font-size: var(--font-size-small);
  /* height: 0; */
  margin: 0;
}

.note {
  margin: 0;
  color: var(--color-text-light);
  font-size: var(--font-size-small);
}

.large {
  height: 3rem;
  line-height: 2.75rem;
}
