@import "../../styles/variables.css";

.root {
  background: color-mod(var(--maletek-red) alpha(5%));
  border: 2px solid var(--maletek-red);
  border-radius: 3px;
  position: absolute;
  width: 50%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: red;
  padding: var(--margin-half);
}

.close {
  cursor: pointer;
  float: right;
}
.icon {
  color: black;
  width: 25px;
  height: 25px;
}
.title {
  margin: 0 0 var(--margin-quarter);
  font-size: var(--font-size-normal);
}

.subtitle {
  margin: 0;
  font-size: var(--font-size-normal);
  word-wrap: break-word;
}
