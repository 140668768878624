@import "../styles/variables.css";

.root {
  margin-top: var(--margin-half);
  @media print {
    float: none;
    margin-top: 0;
    max-width: 100%;
  }
}

.reportTitle {
  margin-bottom: 0px;
  display: block;
  font-size: var(--font-size-xxlarge);
  @media print {
    margin-top: 3px;
    page-break-inside: avoid;
  }
}

.reportSubtitle {
  padding-top: 20px;
  border-top: 10px solid var(--color-border-light);
  display: block;
  font-size: var(--font-size-xxlarge);
  font-weight: bold;
  margin-top: 0px;
}

.deleteButton {
  float: right;
  margin-top: 2rem;
  margin-right: var(--margin) !important;
}

.reportDate {
  margin-top: 10px;
  @media print {
    font-size: var(--font-size-xlarge);
  }
}

.reportClient span {
  font-weight: 200;
}
.generated {
  font-size: var(--font-size-normal);
}

@media print {
  .root .reportSubtitle {
    page-break-before: always;
  }
  .root .reportSubtitle.first {
    page-break-before: avoid;
  }
  h3 {
    page-break-after: avoid;
  }
  h4 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
}

.reportNote {
  font-size: var(--font-size-normal);
  left: 1.2rem;
  margin-top: 0px;
  margin-bottom: 26px;
  display: block;
  line-height: 0;
}

.edit {
  margin-left: 10px;
  margin-bottom: 0px;
  color: var(--color-text-lighter);
  text-decoration: underline;
}

.countersWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media print {
    page-break-inside: avoid;
  }
}

.contentLeft {
  max-width: 300px;
}

.timezone {
  background: var(--color-background-light);
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--color-text);
  padding: 2px 6px 3px 6px;
  border-radius: 4px;
}

.stackedNote {
  font-weight: bold;
  font-size: var(--font-size-small);
  color: var(--color-text-light);
}

.reportMessage {
  font-size: calc(var(--font-size-normal) * 0.9);
  margin-bottom: 0;
  font-weight: normal;
  page-break-inside: avoid;
  page-break-after: avoid;
}
