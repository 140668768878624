@font-face {
  font-family: NeueHaas;
  src: url(./fonts/NeueHaasUnicaW1G-Regular.woff2) format("woff2"),
    url(./fonts/NeueHaasUnicaW1G-Regular.woff) format("woff");
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: NeueHaas;
  src: url(./fonts/NeueHaasUnicaW1G-Bold.woff2) format("woff2"), url(./fonts/NeueHaasUnicaW1G-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}
