@import "../../styles/variables.css";
.root {
}
.map {
  position: relative;
  height: 65vw;
  margin-bottom: 70px;
  @media (--large) {
    height: 40vw;
  }
}

.compact .map {
  height: 45vw;
  @media (--large) {
    height: 25vw;
  }
}

.crosshair {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;

  &:before {
    content: "";
    display: block;
    height: 60px;
    border-left: 1px solid black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    display: block;
    width: 60px;
    border-top: 1px solid black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
  }
}
.marker {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 5px;
  height: 5px;
  border: 1px solid black;
  background: color-mod(var(--maletek-red) alpha(60%));
}

.locker {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 60px;
  padding: 11px;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  stroke: gray;
  border: 2px solid white;
  &.hover {
    cursor: pointer;
    background: white;
    border: 2px solid gray;
  }

  & .lockerIcon {
    stroke: inherit;
    margin-top: -2px;
    fill: white;
    width: 20px;
    height: 20px;
  }

  &.current {
    stroke: var(--maletek-green);
    &:after {
      border-color: var(--maletek-green);
    }
  }
}

.location {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  padding: 11px;
  text-align: center;
  stroke: var(--color-blue);
  border-radius: 50%;

  & .icon {
    stroke: inherit;
    margin-left: 20px;
  }
  &.hover {
    cursor: default;
    border-width: 4px;
  }
  &.mini {
    width: 20px;
    height: 20px;
    stroke: white;
  }
}

.searchBox {
  display: block;
  font-family: inherit;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  height: 2.2rem;
  line-height: 1.75rem;
  border: 1px solid var(--color-border);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  outline: none;
}
