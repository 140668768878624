@import "../styles/variables.css";
.root {
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.item {
  padding: var(--margin-eighth) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }
  &.withErrors {
    padding-bottom: 1.25em;
  }
  & > * {
    margin-bottom: 0;
    margin-left: var(--margin-half);
  }
}

.doorNumber {
  margin: 0;
  flex: 0 0 1.2rem;
}
.doorId {
  flex: 0 0 1rem;
  font-size: var(--font-size-small);
  color: var(--color-text-light);
  letter-spacing: -0.5px;
  text-align: right;
}
.editing .doorNumber {
  margin: 1rem 0 0;
}
.btn {
  margin-top: 1.5rem;
  margin-right: 0;
}
.dimensions {
}
.boxWrapper {
  display: inline-block;

  min-width: 40px;
}
.box {
  display: inline-block;
  background-color: var(--color-background);
}

.availability {
  flex: 1 1 20%;
  white-space: nowrap;
}

.row {
  display: flex;
  justify-content: space-between;
}

.sizeInput {
  flex: 1 1 20%;
}
.statusSelect {
  flex: 1 1 20%;
}
.closeEditing {
  margin-top: 1em;
}

.editButton {
  font-size: var(--font-size-small);
  color: var(--maletek-green);
}
