@import "../../styles/variables.css";

.select {
  width: 100%;
  min-width: 120px;
  margin-bottom: var(--margin-half);
}
.label {
  font-weight: 400;
  color: var(--color-text-light);
  font-size: var(--font-size-small);
}

.select {
  & :global(.Select__menu) {
    z-index: 20;
  }
  & :global(.Select__control) {
    border-radius: 1px;
    border-color: var(--color-border);
    height: 2.2rem;
  }

  & :global(.Select__control--is-focused) {
    border-color: var(--maletek-teal);
    box-shadow: 0 0 0 1px var(--maletek-teal);
    &:hover {
      border-color: var(--maletek-teal);
    }
  }

  & :global(.Select__option--is-focused) {
    background-color: var(--maletek-teal);
  }

  & :global(.Select__clear-indicator) {
    & svg {
      display: none;
    }
    &:before {
      content: "×";
      color: var(--color-text-light);
      font-size: 1rem;
      line-height: 1;
      vertical-align: baseline;
    }
  }
}

.hideValues :global(.Select__multi-value) {
  display: none;
}

.withError {
  & :global(.Select__control) {
    border-color: var(--color-red);
    background: color-mod(var(--color-red) lightness(95%));
  }
}

.error {
  margin: 0;
  margin-top: 0;
  padding-right: 10px;
  color: var(--color-red);
  font-size: var(--font-size-small);
  height: 0;
}

.withError .error {
  height: auto;
}

.note {
  margin: 0;

  color: var(--color-text-light);
  font-size: var(--font-size-small);
}
