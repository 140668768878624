@import "../styles/variables.css";

.loading .line {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: var(--maletek-red);
}

.loading .line:nth-last-child(1) {
  animation: loadingAnimation 1s 0.1s ease infinite;
}
.loading .line:nth-last-child(2) {
  animation: loadingAnimation 1s 0.33s ease infinite;
}
.loading .line:nth-last-child(3) {
  animation: loadingAnimation 1s 0.66s ease infinite;
}

@keyframes loadingAnimation {
  0 {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}
