@import "../styles/variables.css";
.root {
  margin-top: var(--margin-double);
}

.row a {
  display: inline;
}

.name {
  font-weight: bold;
  /* font-size: var(--font-size-medium;); */
}
.date {
  position: relative;
  white-space: nowrap;
  & span:last-child {
    top: 0;
    min-width: 100%;
    left: 0;
    padding: 3px 20px 3px 3px;
    position: absolute;
    background-color: var(--color-background-light);
    opacity: 0;
    white-space: nowrap;
  }
  &:hover {
    & span:last-child {
      opacity: 1;
    }
  }
}

.delivery {
  /* background-color: color-mod(var(--color-green) lightness(96%)); */
}

.reversal {
  background-color: color-mod(var(--color-red) lightness(97%));
}

.return {
  background-color: color-mod(var(--color-orange) lightness(95%));
}

.usage {
  color: green;
  font-weight: bold;
}

.status {
  color: green;
}

.inactive,
.warning {
  color: orange;
}
.defective,
.offline,
.alert {
  color: red;
}
