@import "../styles/variables.css";

.root {
  position: relative;
}

.title {
  font-weight: regular;
  font-size: var(--font-size-xlarge);
  margin-bottom: var(--margin-double);
}

.toolBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--margin);
}

.stats {
  display: flex;
  margin-bottom: var(--margin);
}

.createLocker {
  position: absolute;
  top: 0;
  right: 0;
}

.root {
  margin-top: var(--margin-double);
}

.name {
  font-weight: bold;
  /* font-size: var(--font-size-medium;); */
}

.usage {
  color: green;
  font-weight: bold;
}

.usageBar {
  height: 4px;
  background-color: var(--color-background);
  & .inner {
    height: 4px;
    background-color: var(--color-green);
  }
}
.status {
  width: 100px;
}

.inactive,
.warning {
  color: orange;
}
.defective,
.offline,
.alert {
  color: red;
}

.dot {
  display: inline-block;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.optionType {
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  border-radius: 3px;
  color: white;
  &.enabled {
    background-color: var(--color-green);
  }
  &.danger {
    background-color: var(--color-red);
  }
  &.warning {
    background-color: var(--color-orange);
  }
  &.border-warning {
    color:black;
    border: 0.1em solid var(--color-orange);
  }
  &.border-enabled {
    color:black;
    border: 0.1em solid var(--color-green);
  }
}
