@import "../styles/variables.css";
.root {
}

.head {
  width: 0;
}
.root th {
  width: 15%;
  white-space: nowrap;
  padding-right: var(--margin);
}
.date {
  & span:last-child {
    display: none;
  }
  &:hover span:last-child {
    display: block;
  }
  &:hover span:first-child {
    display: none;
  }
}
