@import "../../styles/variables.css";

.noData {
  position: absolute;
  background-color: var(--color-background-lighter);
  color: var(--color-text-light);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  height: 88%;
  bottom: 0px;
  z-index: -1;
}
