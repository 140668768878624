@import "../../styles/variables.css";
:root {
  --toggle: calc(var(--margin) * 0.75);
}

.root {
  position: relative;
  width: 100%;
  margin-bottom: var(--margin-half);
}

.label {
  font-weight: 400;
  color: var(--color-text);
  font-size: var(--font-size-small);
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.radioGroup {
  position: relative;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 1px 4px var(--color-background);
  overflow: hidden;
}

.radio {
  flex: 1 1 0;
  background: var(--color-background-light);
  border-top: 1px solid var(--color-border-light);
  border-bottom: 1px solid var(--color-border-light);
  padding: 0 10px;
  height: 2.2rem;
  line-height: 1.95rem;
  display: block;
}

.radioLabel {
  height: 2.2rem;
  line-height: 1.95rem;
  display: block;
  text-align: center;
}

.radio:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid var(--color-border-light);
}

.radio:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid var(--color-border-light);
}
.slider {
  position: absolute;
  z-index: 100;
  padding: 0 10px;
  height: 2.2rem;
  line-height: 1.95rem;
  color: white;
  background-color: var(--color-green);
  text-align: center;
  border-radius: 4px;
  pointer-events: none;
  transition: transform 0.15s ease;
}
.root.readOnly {
  & .radio.on .slider {
    background-color: color-mod(var(--color-green) a(60%));
  }
}
