@import "../../styles/variables.css";

.root {
  display: inline-block;
  vertical-align: middle;

  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: -4px 0.5rem 0;
  background-color: var(--color-red);
}
.on {
  background-color: var(--color-green);
}

.semi {
  background-color: var(--color-yellow);
}
