@import "../../styles/variables.css";
:root {
  --toggle: calc(var(--margin) * 0.75);
}

.root {
  display: flex;
  margin-bottom: var(--margin-quarter);
  white-space: nowrap;
}
.checkbox {
  position: absolute;
  z-index: -1;
}
.label {
  font-weight: bold;
  margin-right: calc(var(--margin) / 2);
  padding-left: calc(var(--toggle) * 2 + var(--margin-half));
}
.toggle {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  min-width: calc(var(--toggle) * 2);
  height: calc(var(--toggle) * 1);
  border-radius: calc(var(--toggle) / 2);
  background: var(--color-background-light);
  border: 1px solid var(--color-border-light);
  box-shadow: 0 1px 4px var(--color-background);
}

.toggle .slider {
  position: absolute;
  top: 1px;
  right: calc(50% + 1px);
  transition: right 0.25s ease, background-color 0.25s ease;
  width: calc(50% - 2px);
  height: calc(100% - 2px);
  border: 1px solid var(--color-border);
  border-radius: calc(var(--toggle) / 2);
  background-color: var(--color-background-dark);
  box-shadow: 0 1px 2px var(--color-background);
}
.toggle .innerLabel {
  position: relative;
  vertical-align: top;
  padding: calc(var(--margin) / 8) calc(var(--margin) / 2) 0;
  z-index: 10;
}

.toggle.on {
  & .slider {
    right: 1px;
    background-color: var(--color-green);
  }
  & .innerLabel:last-child {
    color: white;
  }
}

.root.readOnly {
  & .toggle.on .slider {
    background-color: color-mod(var(--color-green) saturation(30%) lightness(70%));
  }
}
