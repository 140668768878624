@import "../../styles/variables.css";

.root {
  display: inline-block;
  position: relative;
  & svg {
    overflow: visible;
    @media print {
      page-break-inside: avoid;
    }
    & rect {
      @media print {
        page-break-inside: avoid;
      }
    }
  }
  @media print {
    page-break-inside: avoid;
  }
}

.graphTitle {
  margin-left: 0.4rem;
  margin-bottom: 0;
  height: 0px;
  z-index: 10;
  @media print {
    page-break-after: avoid;
  }
}

.graphSubtitle {
  position: absolute;
  margin-left: 0.5rem;
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: var(--font-size-small);
  font-weight: 500;
  color: var(--maletek-black);
}

.link {
  opacity: 0.8;
  transition: opacity 0.1s ease-in;
}

.link:hover {
  opacity: 1;
}
