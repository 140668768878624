@import "../styles/variables.css";
.root {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.intro {
  flex: 0 0 100%;
  font-size: var(--font-size-large);
  margin: 1rem 0;
}
.status {
  flex: 1 1 600px;
}
.statusTitle {
  font-size: var(--font-size-xlarge);
}

.activity {
  display: none;
  flex: 0 1 400px;
  padding-left: var(--margin);
}

.errored,
.completed {
  display: inline-block;
  margin-right: var(--margin);
}
.errored {
  color: var(--color-red);
  font-weight: bold;
}
.completed {
  color: var(--color-text-light);
}

.menuBlocks {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
}
.block {
  flex: 1 1 400px;
  padding: var(--margin);
  height: calc(200px + var(--margin-double));
  text-align: center;
  line-height: 200px;
  & a {
    color: white;
    text-decoration: none;
    font-size: var(--font-size-large);
    display: block;
    height: 100%;
    &:hover {
      opacity: 0.6;
    }
  }
}

.status a {
  color: inherit;
  text-decoration: none;
}

.bar {
  font-size: var(--font-size-medium);
  display: flex;
  width: 100%;
  align-items: stretch;
  margin-bottom: 2rem;
}
.barSegment {
  position: relative;
  background: red;
  padding: 0 0.5rem;
  white-space: pre;
  & a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
  &:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  &:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
  &:hover {
    opacity: 0.9;
    z-index: 10;
    cursor: default;
  }
}

.barSegmentLabel {
  position: absolute;
  font-size: var(--font-size-normal);
  color: var(--color-text);
  background: white;
  border-radius: 3px;
  padding: 2px 5px;
  top: 100%;
  left: 0%;
  z-index: 1;
}
