.toolbar {
  display: flex;
  justify-content: flex-end;
}

.select {
  flex: 0 1 300px;
  margin-left: 1rem;

  @media (--small) {
    min-width: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex: 1 1 40%;
  }
}
.id {
  width: 100px;
}
.scope {
  width: 200px;
  & .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
