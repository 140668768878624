@import "../styles/variables.css";

.root {
  position: relative;
}

.title {
  margin-bottom: var(--margin);
}

.orderType {
  padding: 0 0.5em;
  border-radius: 3px;
  color: white;
  &.delivery {
    background-color: var(--color-green);
  }
  &.reversal {
    background-color: var(--color-red);
  }
  &.return {
    background-color: var(--color-orange);
  }
}

.lifecycle {
  margin-bottom: var(--margin);
}
.savedStatus {
  font-size: var(--font-size-normal);
  font-weight: normal;
  display: inline;
  position: absolute;
  top: 0.5rem;
  right: 0;
  color: var(--color-red);
  &.done {
    color: var(--color-green);
  }
}

.fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.notifications {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
}

.errors,
.autoReversalNote {
  margin-bottom: 0;
}

.errorField {
  & label {
    color: var(--color-red);
  }
  & input {
    border-color: var(--color-red);
    border-width: 2px;
    color: var(--color-red) !important;
  }
}
.orderError {
  font-weight: bold;
  color: var(--color-red);
}
.measurementsInput:not(:first-child) {
  margin-left: 2vw !important;
}
.measurementsIcon {
  align-self: center;
  margin-left: 2vw;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin-top: 0.75em;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.readOnly {
  pointer-events: none;
  opacity: 0.5;
}
/* PRINT STYLES */
@media print {
  @page {
    size: auto;
  }
  .barcode {
    position: relative;
    display: block;
    width: 10cm;
    height: 5cm;
    margin: 0;
    padding: 1cm;
    text-align: center;
    & img {
      width: 8cm;
      height: auto;
    }
  }
}
