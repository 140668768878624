.root {
}

.readOnly {
  pointer-events: none;
}

.toolBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: var(--margin);
}

.select {
  width: auto;
  min-width: 160px;
  margin-right: 2rem;

  @media (--small) {
    min-width: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex: 1 1 40%;
  }
}

.optionType {
  display: inline-block;
  margin: 0.125em 0.5em 0.125em 0;
  padding: 0 0.5em;
  border-radius: 3px;
  color: black;
  border: 1px solid var(--color-background-light);

  &.enabled {
    color: white;
    background-color: var(--color-green);
    border-color: var(--color-green);
  }
  &.danger {
    color: white;
    background-color: var(--color-red);
    border-color: var(--color-red);
  }
  &.warning {
    color: white;
    background-color: var(--color-orange);
    border-color: var(--color-orange);
  }
  &.border-warning {
    color: black;
    border: 1px solid var(--color-orange);
  }
  &.border-enabled {
    color: black;
    border: 0.1em solid var(--color-green);
  }
}
