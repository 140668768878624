@import "../../styles/variables.css";

.root {
  position: relative;
  margin-bottom: var(--margin-half);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section {
  transition: 0.2s font-size ease, 0.2s opacity ease;
  margin: 0;
  @media print {
    display: none;
  }
}
.titles {
  @media (--medium) {
    max-width: calc(100% - 400px);
  }
}
.title {
  margin-top: var(--margin-half);
  transition: 0.2s font-size ease;
  font-size: var(--font-size-large);

  @media (--medium) {
    font-size: var(--font-size-xxlarge);
  }
  @media print {
    font-size: var(--font-size-xxlarge);
  }
}
.status {
  min-height: 2rem;
  /* font-weight: bold; */
  font-size: var(--font-size-normal);
  color: var(--color-text-light);
}
.actions {
  display: flex;
  align-items: center;
  bottom: 0;

  right: 0;
  & > * {
    margin-left: var(--margin-half);
    margin-bottom: 0;
  }
}

.compact {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  box-shadow: 0 0 30px rgb(220, 220, 220);
  padding: 0 var(--margin);
  z-index: 200;
  background: white;

  & .title {
    font-size: calc(var(--font-size-large) * 0.5);
    @media (--medium) {
      font-size: calc(var(--font-size-xlarge) * 0.5);
    }
  }

  & .subtitle {
    display: flex;
    align-items: baseline;
  }

  & .subtitle > * {
    padding-left: 0;
    font-size: calc(var(--font-size-normal));
    margin: 0 1rem 0.5rem 0;
    @media (--medium) {
      font-size: calc(var(--font-size-small));
      margin: 0 1rem 0.5rem 0;
    }
  }

  & .section {
    font-size: 0;
    opacity: 0;
  }
}
