@import "../styles/variables.css";
.root {
  display: flex;
  flex-wrap: wrap;
  @media print {
    display: block;
    @page {
      size: auto;
    }
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media (--large) {
    flex-wrap: nowrap;
    min-width: 70vw;
  }
}
.select {
  margin-right: 1rem;
  flex: 1 0 100px;
  @media (--small) {
    margin-right: 0;
  }
}
.button {
  margin-top: 1.5rem;
}

.intro {
  text-align: center;
  flex: 0 0 100%;
  font-size: var(--font-size-xlarge);
}

.menuBlocks {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
}
.block {
  flex: 1 1 400px;
  padding: var(--margin);
  height: calc(200px + var(--margin-double));
  text-align: center;
  line-height: 200px;
  & a {
    color: white;
    background-color: var(--maletek-green);
    text-decoration: none;
    font-size: var(--font-size-large);
    display: block;
    height: 100%;
    &:hover {
      opacity: 0.6;
    }
  }
}
