@import "../../styles/variables.css";

.root {
  vertical-align: middle;
  display: inline-block;
  height: 1.1rem;
  line-height: 1rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  font-size: var(--font-size-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--color-background-light);
  margin: 0 0.5rem;
}

.error {
  background-color: var(--color-red);
  color: white;
}

.success {
  background-color: var(--color-green);
  color: white;
}
