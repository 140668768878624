@import "../../styles/variables.css";

.root {
  font-size: var(--font-size-normal);
  font-family: inherit;
  display: inline-block;
  cursor: pointer;
  background-color: color-mod(var(--maletek-teal) lightness(95%));
  padding: 0rem 1rem;
  height: 2rem;
  text-align: center;
  line-height: 1.75rem;
  margin-right: 1rem;
  min-width: 100px;
  margin-bottom: 1rem;
  color: var(--color-text);
  text-decoration: none;
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid var(--maletek-teal);

  &:hover {
    background-color: var(--maletek-teal);
  }

  &:last-child {
    margin-right: 0rem;
  }
  &.primary {
    background-color: var(--maletek-red);
    border: 1px solid color-mod(var(--maletek-red) blend(#000 15%));
    color: white;
  }
  &.primary:hover {
    background-color: color-mod(var(--maletek-red) lightness(42%));
    color: white;
  }
  &.destructive {
    background: white;
    color: rgb(171, 171, 171);
    border-color: rgb(220, 220, 220);
    &:hover {
      background-color: color-mod(var(--maletek-red) lightness(42%));
      color: white;
    }
  }
  &.invert {
    background-color: white;
    border: 1px solid color-mod(white blend(#000 15%));
    color: var(--color-text);
  }
  &.noMargin {
    margin-bottom: 0;
  }
  &.disabled {
    background-color: var(--color-border-light);
    border-color: var(--color-border);
    color: var(--color-text-lighter);
    pointer-events: none;
  }
  &.warning {
    background-color: var(--maletek-red);
    color: white;
  }
}
a.root {
}

.root.large {
  height: 3rem;
  line-height: 2.75rem;
}

.root.small {
  min-width: auto;
  height: 1.5rem;
  font-size: var(--font-size-small);
  font-weight: bold;
  line-height: 1.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.root svg {
  margin-left: 0.25rem;
}

.loading {
  animation: rotate 2s linear infinite;
  margin-right: 0.5rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
