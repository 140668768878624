@import "../styles/variables.css";

.lockerLegend {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  position: absolute;
  right: 0;
  top: 74px;
  z-index: 10;
  background-color: white;
}

.legendLabel {
  display: flex;
  cursor: pointer;
  align-items: center;
  opacity: 0.8;
  padding: 5px 15px;
  border-radius: 20px;
  transition: opacity 0.2s, background-color 0.2s;
  background-color: white;
  & a {
    text-decoration: none;
    color: var(--color-text);
  }
}

.legendLabel:hover {
  opacity: 1;
  background-color: var(--color-background-lighter);
}

.legendTitle {
  padding-left: 15px;
}
