@import "./variables.css";
@import "./fonts.css";

@page {
  max-width: 100%;
  size: A4;
}

html {
  font-size: var(--font-size-base);
  box-sizing: border-box;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -webkit-print-color-adjust: exact !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: NeueHaas !important;
  margin: 0;
  @media print {
    overflow: visible;
  }
}

main {
  margin: 0 var(--margin) var(--margin);
}

h1,
h2,
h3,
h4 {
  margin: 1rem 0;
  line-height: 1;
  & a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: var(--maletek-red);
    }
  }
  @media print {
    page-break-after: avoid;
  }
}
a {
  cursor: pointer;
}
p {
  font-size: var(--font-size-medium);
}
small {
  display: inline-block;
  line-height: 1.3;
}

hr {
  border-top: 1px solid var(--color-border-light);
  border-left: none;
}

.mainTitle {
  font-size: var(--font-size-xlarge);
}
