@import "./variables.css";

.grid {
  position: relative;
  width: calc(100% + var(--margin));
  margin-left: calc(-1 * var(--margin-half));
  display: flex;
  flex-wrap: wrap;
}

.nest {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
}

.full,
.half,
.quarter,
.third {
  position: relative;
  padding: 0 var(--margin-half);
  margin-top: var(--margin-quarter);
  margin-bottom: var(--margin);
  @media print {
    float: none;
    margin-top: 0;
    margin-bottom: var(--margin);
  }
}

.full {
  flex: 0 0 100%;
}

.half {
  flex: 0 0 100%;
  @media (--large) {
    flex: 0 0 50%;
  }
  @media print {
    flex: 0 0 100%;
  }
}

.quarter {
  flex: 0 0 100%;
  @media (--large) {
    flex: 0 0 50%;
  }
  @media (--large) {
    flex: 0 0 25%;
  }
}

.third {
  flex: 0 0 100%;
  @media (--large) {
    flex: 0 0 calc(100% / 3);
  }
}
