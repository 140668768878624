.root {
}

.list {
  & th {
    width: 20px;
    padding-right: 2rem;
  }
}
.doors {
  background-color: rgb(240, 240, 240);
}
.ordersFilters {
  text-align: center;
}
.ordersFilter {
  color: var(--color-text-light);
  text-decoration: uppercase;
  letter-spacing: 0.01em;
}
.wrapper {
  margin-bottom: var(--margin);
}
.wrapper > div {
  display: inline-block;
  margin-right: var(--margin);
}
